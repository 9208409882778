import React, { useEffect } from "react";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import { BiArrowBack } from "react-icons/bi";

export default function AppPricing() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/apps");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Mobile Apps</p>
          <h1 className="roboto xlarge_text all_caps">Pricing</h1>
          <Spacer height={15} />
          <div>
            <p>
              Prices are determined by the complexity of the features provided,
              and the services provided after they are published. Every app for
              each business has been created before we reach out. It is our plan
              to speed up the process and give the business a more complete look
              at everything that they recieve from the app. Here is a list of
              pricing plans that go with each app we provide.
            </p>
            <br />

            <h3 className="medium_text color2 roboto">Coffee Shop App</h3>
            <Spacer height={10} />
            <h3>Tier 1</h3>
            <p>Complete app, with design modifications.</p>
            <Spacer height={20} />
            <h2>$350 initial fee</h2>
            <h2>$50 / monthly services fee</h2>
            <Spacer height={20} />
            <h3>Tier 2</h3>
            <p>
              Complete app, with design modifications, and full set up support
              (ex. Set up menu, schedule, merchandise, etc.). Our experts are
              already familiar with the apps, and can provide great help if
              needed.
            </p>
            <Spacer height={20} />
            <h2>$350 initial fee</h2>
            <h2>$50 per 25 items (if menu or store) or per hour.</h2>
            <h2>$50 / monthly services fee</h2>
            <Spacer height={20} />
            <h3>Additional Fees</h3>
            <p>Additional 25GB Data Storage - <b>+ 25 / mo</b></p>
            <p>Multiple Locations - <b>$100</b></p>
            <p>Custom app features - <b>Get Quote</b></p>
            
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color2">mobile app</span> is a pivotal digital
              hub, enabling businesses and individuals to access information,
              connect seamlessly, and conduct essential transactions on the go.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
