import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";

export default function Apps() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <p className="all_caps">Happy Code Dev</p>
          <h1 className="roboto xlarge_text all_caps">Mobile Apps</h1>
          <Spacer height={15} />
          <div>
            <p>
              Embarking on the digital journey, mobile apps emerge as true
              catalysts for change. With{" "}
              <span className="pointer color2" onClick={() => {navigate("/")}}>
                Innovative Internet Creations - Happy Code Development Services
              </span>
              , your gateway to a cutting-edge mobile app solution opens wide.
              Crafted meticulously to amplify accessibility and user experience,
              our mobile apps stand as testaments to technological finesse. We
             define norms by seamlessly incorporating the latest trends and
              innovative features, setting a new bar for convenience and
              engagement. Be it personal efficiency or business expansion, a
              mobile app fashioned by Innovative Internet Creations boldly
              leads, marking the forefront of contemporary mobile technology and
              user-centric design.
            </p>
          </div>
          <Spacer height={15} />
          <h3 className="bold large_text">Links</h3>

          <div className="websites_row1_links">
            <div className="">
              <Link1
                text={"Members"}
                classes={"underline courier medium_text color2 all_caps"}
                onClick={() => {navigate("/app-members")}}
              />
              <Link1
                text={"Services"}
                classes={"underline courier medium_text color2 all_caps"}
                onClick={() => {navigate("/app-services")}}
              />
              <Link1
                text={"Pricing"}
                classes={"underline courier medium_text color2 all_caps"}
                onClick={() => {navigate("/app-pricing")}}
              />
              <Link1
                text={"Businesses"}
                classes={"underline courier medium_text color2 all_caps"}
                onClick={() => {navigate("/app-businesses")}}
              />
              <Link1
                text={"FAQ"}
                classes={"underline courier medium_text color2 all_caps"}
                onClick={() => {navigate("/app-faq")}}
              />
              <Link1
                text={"Policy"}
                classes={"underline courier medium_text color2 all_caps"}
                onClick={() => {navigate("/app-policy")}}
              />
            </div>
            <div className="no_scrollY">
              <Image
                image={img1}
                height={"100%"}
                width={"100%"}
                classes={"cover"}
                radius={0}
              />
            </div>
          </div>
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
            A <span className="color2">mobile app</span> is a pivotal digital hub, enabling businesses and individuals to access information, connect seamlessly, and conduct essential transactions on the go.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
