import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";

export default function WebsiteMembers() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/websites");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Websites</p>
          <h1 className="roboto xlarge_text all_caps">Members</h1>
          <Spacer height={15} />
          <div>
            <p>
              <span className="color1">Innovative Internet Creations</span> provides services for website maintenance every month; unlike any other company. Websites require consistent updates to stay on the first page of Google search. Ultimately, the goal is to be the first link to pop up on the first page when searching for "coffee shop near me", or "real-estate near me".
            </p>
            <br/>
            <p>
              Our Innovative Internet Creations mobile app lets you create tickets for updates, view data coming from user interaction with the website, pay invoices, get real-time updates from developer, communicate with development staff, and more. This app is available for iPhone and Android mobile devices.
            </p>
          </div>
          <Spacer height={15} />
         
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color1">website</span> is a vital online
              presence for businesses and individuals, enabling information
              sharing, connection, and essential transactions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
