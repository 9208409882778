import React, { useEffect, useState } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";
import { Textfield } from "../COMPONENTS/Textfield";

export default function AppFAQ() {
  const location = useLocation();
  const navigate = useNavigate();

  //
  const [searchText, setSearchText] = useState("");
  const [faqs, setFaqs] = useState([]);
  const allFaqs = [
    {
      Question: "Why choose Innovative Internet Creations to make an app?",
      Answer:
        "Innovative Internet Creations prioritizes a balance between quality and efficiency. Our commitment extends to offering competitive pricing without compromising standards. Unlike the industry standard of $2000 for similar apps, ours start at $350. As the architects of our apps, built from the ground up, we possess the expertise to deliver prompt fixes and updates when needed, ensuring a swift and effective resolution.",
    },
    {
      Question:
        "What should the business do if the customer is having an issue with the app?",
      Answer:
        "The first response should be to ask what the problem is. It is possible that the user might have missed something or clicked on wrong component. If the problem was not user error, then use the Innovative Internet Creations app to communicate with a developer. As soon as we get the notification, we will begin to troubleshoot.",
    },
    {
      Question: "Why purchase an app rather than use a free app tool?",
      Answer:
        "The purpose of having an app is to promote the brand. When a user downloads your app, it is reaffirming the business brand with the logo, name, and information. In other words, it is creating loyalty to the business. We also believe that the business owners should focus on what they do best, and leave the development to us. No need to waste time learning and creating.",
    },
    {
      Question: "Can the app be connected to a Square POS system?",
      Answer:
        "Unfortunately, no. At the moment, the app processes payments using Stripe API. We are working on creating a component for Square payment processing. We will be sure to provide updates on this matter.",
    },
    {
      Question: "Does the demo contain the final design?",
      Answer:
        "No. Although the design is meant to resemble a finished product, the design can always be changed according to the design of the business logo or website. That way the user can have a seamless experience across platforms.",
    },
    {
      Question: "How can I retrieve financial data from sales through the app?",
      Answer:
        "There are two ways to get your financial data. The paired admin app will have a sales, or finances section which will have all requested information. You can also export your financial data from the Stripe dashboard. Stripe is the payment processing API we use for all apps. They provide a detailed accumulation of information including the transaction fees.",
    },
    {
        Question: "Can changes be made after the app has been published to the app stores?",
        Answer: "Yes. They may be a need to add new features or updates. With apps, we submit updates to Apple and Google which may take up to two days to get reviewed and approved. Users can update the app at any time after that."
    },
    {
        Question: "Is the app available for Android and iPhone?",
        Answer: "Yes!"
    },
    {
        Question: "How can the business access business related information?",
        Answer: "Every app comes with a paired admin app. This is where the business can access financial data, orders, menu, schedule, etc."
    },
    {
        Question: "How quickly will the changes on the admin app reflect on the customer app?",
        Answer: "All changes are instantaneous."
    },
    {
        Question: "Will the user receive notifications on their device?",
        Answer: "Yes. The user will receive updates on any interactions performed on the app. For example, the user will recieve a notification if an order was completed, or when a new blog post was added."
    },
    {
        Question: "Are there any transaction fees taken from user payments?",
        Answer: "Stripe API keeps a transaction fee from each payment (2.9% + 30¢ per successful charge for domestic cards)."
    },
    {
        Question: "Who has access to modify the menu, view sales reports, and create rewards, etc.?",
        Answer: "To keep business information safe, we add a lock screen to confidential screens that can be accessed by entering a pin. The business owner or manager can decide who can have access."
    },
    {
        Question: "What process do we take to remove as many problems with the app from the start?",
        Answer: "Before the app is introduced to the public, we hold a final consultation through video call and run through the entire app and all of its processes. We encourage all employees to participate to learn how to use and troubleshoot in case there are any issues. Once finished and cleared, the app can be promoted to all customers."
    },
    {
        Question: "What is the cost of the app?",
        Answer: "The pricing depends on the app. Some apps can be purchased for as low as $300. We believe in giving the lowest prices for small businesses who want to grow and increase revenue. In comparison, quotes we have received from other sources start at $2000!"
    }
  ];

  function onTypeKeyword(text) {
    setFaqs([
      ...allFaqs.filter(
        (ting) => ting.Question.includes(text) || ting.Answer.includes(text)
      ),
    ]);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
    setFaqs([...allFaqs]);
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/apps");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Mobile Apps</p>
          <h1 className="roboto xlarge_text all_caps">FAQ</h1>
          <Spacer height={15} />
          <div>
            <p>Have a question? Find your answer.</p>
            <br />
            <Textfield
              placeholder={"Type any keyword..."}
              setter={setSearchText}
              onTyping={onTypeKeyword}
            />
            <Spacer height={15} />
            <div>
              {faqs.map((faq, i) => {
                return (
                  <div key={i} className="padding_v">
                    <p className="medium_text bold">Q: {faq.Question}</p>
                    <p>A: {faq.Answer}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color2">mobile app</span> is a pivotal digital
              hub, enabling businesses and individuals to access information,
              connect seamlessly, and conduct essential transactions on the go.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
