import React, { useEffect } from "react";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";
import { Link1 } from "../COMPONENTS/Link";

export default function WebsitePages() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/websites");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Websites</p>
          <h1 className="roboto xlarge_text all_caps">Pages</h1>
          <Spacer height={15} />
          <div>
            <p>
              <span className="color1">Innovative Internet Creations</span> has
              worked diligently to provide its members with fully equipped
              pages, incorporating essential meta-data, accessibility features,
              great design across all devices, and a well-organized structure.
            </p>
            <br />
            <p>
              Over time, we continue to add more pages for you to choose from,
              each one created from scratch.
            </p>
            <br />
            <Link1
              text={"Home 1"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app", "blank");
              }}
            />
            <Link1
              text={"Home 2"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/2", "blank");
              }}
            />
            <Link1
              text={"About"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open(
                  "https://iicweblinetemplate.web.app/about",
                  "blank"
                );
              }}
            />
            <Link1
              text={"Services"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open(
                  "https://iicweblinetemplate.web.app/services",
                  "blank"
                );
              }}
            />
            <Link1
              text={"Contact"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open(
                  "https://iicweblinetemplate.web.app/contact",
                  "blank"
                );
              }}
            />
             <Link1
              text={"Schedule"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/schedule", "blank");
              }}
            />
            <Link1
              text={"Menu"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/menu", "blank");
              }}
            />
            <Link1
              text={"FAQ"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/faq", "blank");
              }}
            />
            <Link1
              text={"Locations"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/locations", "blank");
              }}
            />
            <Link1
              text={"Events"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open(
                  "https://iicweblinetemplate.web.app/events",
                  "blank"
                );
              }}
            />
            <Link1
              text={"Reviews"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open(
                  "https://iicweblinetemplate.web.app/reviews",
                  "blank"
                );
              }}
            />
            <Link1
              text={"Blog"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/blog", "blank");
              }}
            />
            <Link1
              text={"Team"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/team", "blank");
              }}
            />
            <Link1
              text={"Login"}
              classes={"large_text underline courier color1"}
              onClick={() => {
                window.open("https://iicweblinetemplate.web.app/login", "blank");
              }}
            />
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color1">website</span> is a vital online
              presence for businesses and individuals, enabling information
              sharing, connection, and essential transactions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
