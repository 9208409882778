import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";

export default function AppBusinesses() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/apps");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Mobile Apps</p>
          <h1 className="roboto xlarge_text all_caps">Businesses</h1>
          <Spacer height={15} />
          <div>
            <p>
              <span className="color2">Innovative Internet Creations</span> has
              created apps for different type of businesses. We include all the
              necessary features depending on what the business needs. If we
              approach you, it means we have an app ready to be shopped out.
            </p>
            <br />
            <p>
              If you have any questions or want to get your own app,{" "}
              <span
                className="color2 pointer"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact Us
              </span>
              .
            </p>
            <br />
            <h3 className="medium_text color2 roboto">Coffee Shops</h3>
            <p>
              Transform your coffee shop operations with our Coffee Shop App.
              Streamline orders, build customer loyalty with a point rewards
              system, and engage your audience through our dynamic blog. Elevate
              your coffee business with a seamless blend of convenience and
              connection. Partner with us to craft a rewarding coffee experience
              for your customers!
            </p>
            <br />
            <h3 className="medium_text color2 roboto">Food Trucks</h3>
            <p>
              Optimize your food truck venture with our professional Food Truck
              App. Manage orders seamlessly, foster loyalty through point
              rewards, and keep customers engaged with curated blog content.
              Elevate your mobile culinary experience – choose efficiency and
              sophistication for your business success.
            </p>
            <br />
            <h3 className="medium_text color2 roboto">Barber Shops</h3>
            <p>
              Improve your barber shop with our Barber Shop App. Manage
              appointments, boost loyalty with points, and stay connected with
              engaging updates. Whether styling or on the go, our app ensures a
              seamless and rewarding experience. Elevate your barber shop with
              convenience and connection – embrace excellence now!
            </p>
            <br />
            <h3 className="medium_text color2 roboto">Restaurants</h3>
            <p>
              Revolutionize your restaurant with our Restaurant App. Manage
              orders, foster loyalty through points, and engage diners with
              dynamic updates. Whether in the kitchen or on the move, our app
              ensures a seamless and rewarding experience. Elevate your
              restaurant with convenience and connection – embrace innovation
              today!
            </p>
            <br />
            <h3 className="medium_text color2 roboto">Nail Salons</h3>
            <p>
              Revitalize your nail salon using our Nail Salon App. Effectively
              handle appointments, foster loyalty through a points system, and
              stay engaged with dynamic updates. Whether perfecting nail art or
              organizing schedules, our app guarantees a smooth and rewarding
              experience. Elevate your nail salon with convenience and
              connection – embrace innovation today!
            </p>
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color2">mobile app</span> is a pivotal digital
              hub, enabling businesses and individuals to access information,
              connect seamlessly, and conduct essential transactions on the go.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
