import React, { useEffect, useState } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";
import { Textfield } from "../COMPONENTS/Textfield";

export default function WebsiteFAQ() {
  const location = useLocation();
  const navigate = useNavigate();

  //
  const [searchText, setSearchText] = useState("");
  const [faqs, setFaqs] = useState([]);
  const allFaqs = [
    {
      Question: "How is the cost of a website determined?",
      Answer:
        "The cost depends on the scale of the website and the amount of customization that is required. However, our prices are incredibly low compared to any other development company. On average, a website may cost as low as $150.",
    },
    {
      Question: "Why is it important to have a website?",
      Answer:
        "Having a website for business is essential as it serves as a crucial online presence, providing accessibility, visibility, and a platform for effective communication, thereby enabling businesses to reach a wider audience and stay competitive in the digital landscape.",
    },
    {
      Question:
        "What services are included in the website development package?",
      Answer:
        "Our website development package includes a range of services such as custom design, responsive layout, content creation, integration of essential features, SEO optimization, and ongoing support to ensure your website meets your business needs.",
    },
    {
      Question: "How long does it take to develop a website?",
      Answer:
        "The development time varies based on the complexity and features required. On average, a standard website may take two days, while more complex projects can take longer. We work efficiently to deliver high-quality websites within a reasonable timeframe.",
    },
    {
      Question:
        "Can I request changes to the website after it's been developed?",
      Answer:
        "Yes, we provide post-development support, and you can request changes or updates to your website which is covered by the montlhy maintenance subscription. Depending on the nature of the changes, there may be additional costs, which will be communicated and agreed upon before implementation.",
    },
    {
      Question: "Do you offer e-commerce website development?",
      Answer:
        "Yes, however we do recommend to use Shopify if the main purpose of the site is for e-commerce. We specialize in online presence and displaying information with breautiful design.",
    },
    {
      Question: "What is the difference between a static and dynamic website?",
      Answer:
        "A static website has fixed content that doesn't change unless manually updated, while a dynamic website's content can be updated automatically and may include features like user interactions and database-driven content.",
    },
  ];

  function onTypeKeyword(text) {
    setFaqs([
      ...allFaqs.filter(
        (ting) => ting.Question.includes(text) || ting.Answer.includes(text)
      ),
    ]);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
    setFaqs([...allFaqs]);
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/websites");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Websites</p>
          <h1 className="roboto xlarge_text all_caps">FAQ</h1>
          <Spacer height={15} />
          <div>
            <p>Have a question? Find your answer.</p>
            <br />
            <Textfield
              placeholder={"Type any keyword..."}
              setter={setSearchText}
              onTyping={onTypeKeyword}
            />
            <Spacer height={15} />
            <div>
              {faqs.map((faq, i) => {
                return (
                  <div key={i} className="padding_v">
                    <p className="medium_text bold">Q: {faq.Question}</p>
                    <p>A: {faq.Answer}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color1">website</span> is a vital online
              presence for businesses and individuals, enabling information
              sharing, connection, and essential transactions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
