import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";
import { scrollToElement } from "../Global";

export default function AppPolicy() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/apps");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Mobile Apps</p>
          <h1 className="roboto xlarge_text all_caps">Privacy Policy</h1>
          <Spacer height={15} />
          <div>
            <p>
              <span className="color2">Innovative Internet Creations</span> is
              dedicated to crafting extraordinary applications that enhance the
              lives of individuals worldwide. Our mission is to harness the
              power of technology to simplify and enrich daily experiences.
              Central to our ethos is earning your trust through the integrity
              and goodwill embedded in every facet of our mobile applications.
              With a commitment to excellence and a passion for innovation, we
              aspire to deliver solutions that not only meet your needs but
              exceed your expectations, ensuring that each interaction with our
              creations leaves a positive and lasting impact.
            </p>
            <br />
            <div
              className="padding"
              style={{ backgroundColor: "rgba(0,0,0,0.05)" }}
            >
              <div className="horizontal align_center">
                <h2>1.</h2>
                <Link1 classes={"underline courier medium_text"} text={"Pop! Market"} onClick={() => {
                  scrollToElement("popmarket")
                }} />
              </div>
              <div className="horizontal align_center">
                <h2>2.</h2>
                <Link1 classes={"underline courier medium_text"} text={"Cafeina Cafe App"} onClick={() => {
                  scrollToElement("cafeinacafe")
                }} />
              </div>
              <div className="horizontal align_center">
                <h2>3.</h2>
                <Link1 classes={"underline courier medium_text"} text={"Viva Coffee App"} onClick={() => {
                  scrollToElement("vivacoffee")
                }} />
              </div>
              <div className="horizontal align_center">
                <h2>4.</h2>
                <Link1 classes={"underline courier medium_text"} text={"Edmusica Teachers"} onClick={() => {
                  scrollToElement("edmusicateachers")
                }} />
              </div>
            </div>
            <br />
            <h3
              id="popmarket"
              style={{ color: "#2F70D5" }}
              className="medium_text roboto"
            >
              Pop! Market
            </h3>
            <br />
            <div>
              <p>Effective Date: 02/12/2024</p>
              <p>
                This Privacy Policy outlines how Innovative Internet Creations
                ("we," "our," or "us") collects, uses, shares, and protects your
                personal information when you access or use our mobile
                application (Pop! Market). By using the App, you agree to the
                terms of this Privacy Policy.
              </p>
              <br />
              <h2>1. Information We Collect</h2>
              <p>
                When you create an account on our App, we collect your email
                address and display name. Additionally, when you create a new
                sale and list items for sale, we may collect information such as
                your address to facilitate pickup by buyers.
              </p>
              <br />
              <h2>2. How We Use Your Information</h2>
              <p>
                <strong>2.1 Providing Services:</strong> We use the information
                you provide to operate, maintain, and improve our App, as well
                as to communicate with you about your account and sales
                activities.
              </p>
              <p>
                <strong>2.2 Personalization:</strong> We may use your email
                address to send you promotional emails, newsletters, or updates
                about new features or offerings that may be of interest to you.
                You can opt-out of receiving these communications at any time by
                following the unsubscribe instructions provided in the emails.
              </p>
              <br />
              <h2>3. Sharing Your Information</h2>
              <p>
                We may disclose your information if required to do so by law or
                in response to a valid legal request, such as a subpoena or
                court order.
              </p>
              <br />
              <h2>4. Data Security</h2>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, no method
                of transmission over the internet or electronic storage is 100%
                secure. Therefore, while we strive to use commercially
                acceptable means to protect your personal information, we cannot
                guarantee its absolute security.
              </p>
              <br />
              <h2>5. Children's Privacy</h2>
              <p>
                Our App is not directed to children under the age of 13, and we
                do not knowingly collect personal information from children
                under the age of 13. If you are a parent or guardian and believe
                that your child has provided us with personal information
                without your consent, please contact us at
                info@happycodewebsites.com so that we can take appropriate
                action.
              </p>
              <br />
              <h2>6. Changes to this Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We will notify
                you of any material changes to this Privacy Policy by posting
                the updated policy on our App. Your continued use of the App
                after the effective date of the updated Privacy Policy
                constitutes your acceptance of the changes.
              </p>
              <br />
              <h2>7. Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us at
                info@happycodewebsites.com.
              </p>
            </div>
            <br />
            <h3
              id="cafeinacafe"
              style={{ color: "#2F70D5" }}
              className="medium_text roboto"
            >
              Cafeina Cafe App
            </h3>
            <br />
            <div>
              <p>Effective Date: 02/12/2024</p>
              <p>
                This Privacy Policy outlines how Innovative Internet Creations
                ("we," "our," or "us") collects, uses, shares, and protects your
                personal information when you access or use our mobile
                application (Cafeina Cafe App). By using the App, you agree to
                the terms of this Privacy Policy.
              </p>
              <br />
              <h2>1. Information We Collect</h2>
              <p>
                When you create an account on our App, we collect your email
                address and display name. Additionally, when you create a new
                sale and list items for sale, we may collect information such as
                your address to facilitate pickup by buyers.
              </p>
              <br />
              <h2>2. How We Use Your Information</h2>
              <p>
                <strong>2.1 Providing Services:</strong> We use the information
                you provide to operate, maintain, and improve our App, as well
                as to communicate with you about your account and sales
                activities.
              </p>
              <p>
                <strong>2.2 Personalization:</strong> We may use your email
                address to send you promotional emails, newsletters, or updates
                about new features or offerings that may be of interest to you.
                You can opt-out of receiving these communications at any time by
                following the unsubscribe instructions provided in the emails.
              </p>
              <br />
              <h2>3. Sharing Your Information</h2>
              <p>
                We may disclose your information if required to do so by law or
                in response to a valid legal request, such as a subpoena or
                court order.
              </p>
              <br />
              <h2>4. Data Security</h2>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, no method
                of transmission over the internet or electronic storage is 100%
                secure. Therefore, while we strive to use commercially
                acceptable means to protect your personal information, we cannot
                guarantee its absolute security.
              </p>
              <br />
              <h2>5. Children's Privacy</h2>
              <p>
                Our App is not directed to children under the age of 13, and we
                do not knowingly collect personal information from children
                under the age of 13. If you are a parent or guardian and believe
                that your child has provided us with personal information
                without your consent, please contact us at
                info@happycodewebsites.com so that we can take appropriate
                action.
              </p>
              <br />
              <h2>6. Changes to this Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We will notify
                you of any material changes to this Privacy Policy by posting
                the updated policy on our App. Your continued use of the App
                after the effective date of the updated Privacy Policy
                constitutes your acceptance of the changes.
              </p>
              <br />
              <h2>7. Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us at
                info@happycodewebsites.com.
              </p>
            </div>
            <br />
            <h3
              id="vivacoffee"
              style={{ color: "#2F70D5" }}
              className="medium_text roboto"
            >
              Viva Coffee App
            </h3>
            <br />
            <div>
              <p>Effective Date: 02/12/2024</p>
              <p>
                This Privacy Policy outlines how Innovative Internet Creations
                ("we," "our," or "us") collects, uses, shares, and protects your
                personal information when you access or use our mobile
                application (Viva Coffee App). By using the App, you agree to
                the terms of this Privacy Policy.
              </p>
              <br />
              <h2>1. Information We Collect</h2>
              <p>
                When you create an account on our App, we collect your email
                address and display name. Additionally, when you create a new
                sale and list items for sale, we may collect information such as
                your address to facilitate pickup by buyers.
              </p>
              <br />
              <h2>2. How We Use Your Information</h2>
              <p>
                <strong>2.1 Providing Services:</strong> We use the information
                you provide to operate, maintain, and improve our App, as well
                as to communicate with you about your account and sales
                activities.
              </p>
              <p>
                <strong>2.2 Personalization:</strong> We may use your email
                address to send you promotional emails, newsletters, or updates
                about new features or offerings that may be of interest to you.
                You can opt-out of receiving these communications at any time by
                following the unsubscribe instructions provided in the emails.
              </p>
              <br />
              <h2>3. Sharing Your Information</h2>
              <p>
                We may disclose your information if required to do so by law or
                in response to a valid legal request, such as a subpoena or
                court order.
              </p>
              <br />
              <h2>4. Data Security</h2>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, no method
                of transmission over the internet or electronic storage is 100%
                secure. Therefore, while we strive to use commercially
                acceptable means to protect your personal information, we cannot
                guarantee its absolute security.
              </p>
              <br />
              <h2>5. Children's Privacy</h2>
              <p>
                Our App is not directed to children under the age of 13, and we
                do not knowingly collect personal information from children
                under the age of 13. If you are a parent or guardian and believe
                that your child has provided us with personal information
                without your consent, please contact us at
                info@happycodewebsites.com so that we can take appropriate
                action.
              </p>
              <br />
              <h2>6. Changes to this Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We will notify
                you of any material changes to this Privacy Policy by posting
                the updated policy on our App. Your continued use of the App
                after the effective date of the updated Privacy Policy
                constitutes your acceptance of the changes.
              </p>
              <br />
              <h2>7. Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us at
                info@happycodewebsites.com.
              </p>
            </div>
            <br />
            <h3
              id="edmusicateachers"
              style={{ color: "#2F70D5" }}
              className="medium_text roboto"
            >
              Edmusica Teachers
            </h3>
            <br />
            <div>
              <p>Effective Date: 02/12/2024</p>
              <p>
                This Privacy Policy outlines how Innovative Internet Creations
                ("we," "our," or "us") collects, uses, shares, and protects your
                personal information when you access or use our mobile
                application (Edmusica Teachers). By using the App, you agree to
                the terms of this Privacy Policy.
              </p>
              <br />
              <h2>1. Information We Collect</h2>
              <p>
                When you create an account on our App, we collect your email
                address and display name. Additionally, when you create a new
                sale and list items for sale, we may collect information such as
                your address to facilitate pickup by buyers.
              </p>
              <br />
              <h2>2. How We Use Your Information</h2>
              <p>
                <strong>2.1 Providing Services:</strong> We use the information
                you provide to operate, maintain, and improve our App, as well
                as to communicate with you about your account and sales
                activities.
              </p>
              <p>
                <strong>2.2 Personalization:</strong> We may use your email
                address to send you promotional emails, newsletters, or updates
                about new features or offerings that may be of interest to you.
                You can opt-out of receiving these communications at any time by
                following the unsubscribe instructions provided in the emails.
              </p>
              <br />
              <h2>3. Sharing Your Information</h2>
              <p>
                We may disclose your information if required to do so by law or
                in response to a valid legal request, such as a subpoena or
                court order.
              </p>
              <br />
              <h2>4. Data Security</h2>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, no method
                of transmission over the internet or electronic storage is 100%
                secure. Therefore, while we strive to use commercially
                acceptable means to protect your personal information, we cannot
                guarantee its absolute security.
              </p>
              <br />
              <h2>5. Children's Privacy</h2>
              <p>
                Our App is not directed to children under the age of 13, and we
                do not knowingly collect personal information from children
                under the age of 13. If you are a parent or guardian and believe
                that your child has provided us with personal information
                without your consent, please contact us at
                info@happycodewebsites.com so that we can take appropriate
                action.
              </p>
              <br />
              <h2>6. Changes to this Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We will notify
                you of any material changes to this Privacy Policy by posting
                the updated policy on our App. Your continued use of the App
                after the effective date of the updated Privacy Policy
                constitutes your acceptance of the changes.
              </p>
              <br />
              <h2>7. Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us at
                info@happycodewebsites.com.
              </p>
            </div>
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color2">mobile app</span> is a pivotal digital
              hub, enabling businesses and individuals to access information,
              connect seamlessly, and conduct essential transactions on the go.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
