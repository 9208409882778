import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";

export default function AppServices() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/apps");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Mobile Apps</p>
          <h1 className="roboto xlarge_text all_caps">Services</h1>
          <Spacer height={15} />
          <div>
            <p>
              <span className="color2">Innovative Internet Creations</span>{" "}
              provides services for app maintenance every month; unlike any
              other company. There may be a need to add new features to make the
              app be a better fit for users. Our innovative team knows to
              implement just about anything imaginable.
            </p>
            <br />
            <h3 className="medium_text color2 roboto">New Features</h3>
            <p>
              We are dedicated to enhancing your business mobile app by offering
              comprehensive services for the integration of new and advanced
              features. Our experienced team understands the evolving needs of
              businesses in the dynamic mobile landscape. Whether it's
              incorporating cutting-edge functionalities, improving user
              experience, or staying ahead of industry trends, we tailor our
              services to align with your specific business goals. By seamlessly
              integrating innovative features into your mobile app, we empower
              you to provide an enriched experience for your users, stay
              competitive, and foster continuous growth in the ever-evolving
              mobile ecosystem.
            </p>
            <br />
            <h3 className="medium_text color2 roboto">
              Bug Fixes and Troubleshooting
            </h3>
            <p>
              In our commitment to maintaining the optimal functionality of your
              mobile app, our Bug Fixes and Troubleshooting service stands as a
              cornerstone. Our experienced team is dedicated to identifying and
              resolving any glitches, bugs, or issues that may surface within
              your app. Through a systematic approach, we conduct thorough
              diagnostics to pinpoint the root causes of problems, implementing
              swift and effective solutions to ensure a seamless user
              experience. By addressing issues promptly, we not only enhance the
              app's performance but also fortify its reliability, allowing your
              users to engage with your application without disruptions. Our
              proactive bug-fixing strategy aligns with our goal to deliver a
              polished, error-free mobile app that consistently meets the high
              standards expected by both you and your users.
            </p>
            <br />
            <h3 className="medium_text color2 roboto">
              User Interface (UI) and User Experience (UX) Improvements
            </h3>
            <p>
              Elevating your mobile app's success is at the forefront of our
              service, and our expertise in User Interface (UI) and User
              Experience (UX) Improvements plays a pivotal role in achieving
              this goal. We understand that a seamless and visually appealing
              interface is crucial for user satisfaction. Our dedicated team
              meticulously analyzes user interactions, feedback, and industry
              trends to identify areas for enhancement. Through thoughtful
              design adjustments, intuitive navigation, and aesthetic
              refinements, we aim to create an engaging and user-friendly
              experience. Whether it's streamlining workflows, optimizing
              layouts, or incorporating the latest design principles, our UI and
              UX Improvements are tailored to align your app with the evolving
              expectations of your audience. By focusing on the finer details of
              user interaction, we ensure that your mobile app not only meets
              but exceeds user expectations, fostering lasting positive
              impressions and encouraging continued user engagement.
            </p>
            <br />
            <h3 className="medium_text color2 roboto">
              Compliance and Regulation Updates
            </h3>
            <p>
              Our commitment to the integrity and security of your mobile app
              extends to our Compliance and Regulation Updates service. In the
              ever-evolving landscape of digital regulations and data protection
              laws, staying abreast of compliance requirements is paramount. Our
              seasoned team is dedicated to ensuring that your app adheres to
              industry standards, legal frameworks, and app store guidelines.
              Through rigorous assessments and regular updates, we proactively
              address any regulatory changes, mitigating potential risks and
              safeguarding your app's standing. By prioritizing compliance, we
              not only uphold the trust of your users but also ensure the
              longevity and credibility of your mobile app in a dynamic and
              regulated environment. Whether it's GDPR, CCPA, or other relevant
              regulations, we work diligently to keep your app in alignment with
              the latest compliance standards, allowing you to focus on
              delivering a secure and reliable user experience.
            </p>
            <br />
            <h3 className="medium_text color2 roboto">
              Analytics and Reporting
            </h3>
            <p>
              In our Analytics and Reporting service, we go beyond the surface
              of your mobile app's performance, delving deep into the data to
              derive valuable insights that inform strategic decision-making.
              Our comprehensive approach involves the integration of advanced
              analytics tools to capture key metrics, user behavior patterns,
              and app performance indicators. By meticulously analyzing this
              data, we gain a nuanced understanding of how users interact with
              your app, identifying areas for improvement and opportunities for
              innovation. Detailed reports generated from these analytics not
              only offer a snapshot of current app performance but also serve as
              a roadmap for future enhancements. Whether it's user engagement,
              feature popularity, or conversion rates, our Analytics and
              Reporting service empowers you with actionable intelligence,
              enabling data-driven decision-making to refine and optimize your
              mobile app continuously. With a focus on meaningful metrics, we
              help you make informed decisions that enhance user satisfaction,
              drive growth, and keep your mobile app at the forefront of
              industry standards.
            </p>
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color2">mobile app</span> is a pivotal digital
              hub, enabling businesses and individuals to access information,
              connect seamlessly, and conduct essential transactions on the go.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
