import React from "react";
import { Link1 } from "../COMPONENTS/Link";
import { useNavigate } from "react-router-dom";

export default function Navigation() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="padding">
        <Link1
          text={"Home"}
          classes={"all_caps medium_text courier underline"}
          onClick={() => {
            navigate("/");
          }}
        />
        <Link1
          text={"Websites"}
          classes={"all_caps medium_text courier underline"}
          onClick={() => {
            navigate("/websites");
          }}
        />
        <Link1
          text={"Apps"}
          classes={"all_caps medium_text courier underline"}
          onClick={() => {
            navigate("/apps");
          }}
        />
        <Link1
          text={"Pop! Market"}
          classes={"all_caps medium_text courier underline"}
          onClick={() => {
            navigate("/apps");
          }}
        />
        <Link1
          text={"Contact Us"}
          classes={"all_caps medium_text courier underline"}
          onClick={() => {
            navigate("/contact");
          }}
        />
      </div>
    </div>
  );
}
