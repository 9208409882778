import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";

export default function WebsiteServices() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/websites");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Websites</p>
          <h1 className="roboto xlarge_text all_caps">Services</h1>
          <Spacer height={15} />
          <div>
            <p>
              <span className="color1">Innovative Internet Creations</span>{" "}
              provides services for website maintenance every month; unlike any
              other company. Websites require consistent updates to stay on the
              first page of Google search. Ultimately, the goal is to be the
              first link to pop up on the first page when searching for "coffee
              shop near me", or "real-estate near me".
            </p>
            <br />
            <p>
              Apart from ranking, the updating of information is essential to a
              healthy and relevant website. To achieve this, our team must be
              consistent and available to make changes. Download the app to have
              access to member portal.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">SEO Enhancements</h3>
            <p>
              SEO enhancements are crucial for improving your website's
              visibility and performance in search engine rankings. Our team
              conducts thorough keyword research to identify relevant terms and
              phrases that your target audience uses. Through on-page
              optimization, we strategically optimize meta tags, headers, and
              content to align with these keywords and improve search engine
              understanding. We also employ link building strategies to acquire
              high-quality backlinks, enhancing your website's authority.
              Technical SEO audits address issues like broken links and slow
              page speed, while local SEO tactics focus on improving visibility
              in specific geographic areas. Regular analytics and reporting
              provide valuable insights, allowing us to measure the
              effectiveness of our strategies and refine your SEO approach for
              optimal results.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Change Color Palette</h3>
            <p>
              Changing the color palette of a website involves a deliberate and
              thoughtful selection of new colors to refresh and enhance the
              visual appeal and user experience. Our design team understands the
              impact of color on user perception, emotions, and brand identity.
              We work closely with clients to identify their desired aesthetic
              and target audience preferences, and then propose a new color
              scheme that aligns with their vision. We carefully consider the
              psychology behind colors, ensuring the chosen palette reflects the
              desired mood, tone, and message of the website. Whether it's a
              complete overhaul or a subtle adjustment, we skillfully implement
              the new color palette across various design elements, including
              backgrounds, fonts, buttons, and visual accents. This
              transformation not only gives the website a fresh and modern look
              but also contributes to improved user engagement, brand
              recognition, and overall aesthetics.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">
              Change text (wording, color, size, etc)
            </h3>
            <p>
              Changing the text on a website involves refining the wording,
              adjusting the color, and optimizing the size to improve
              readability, user engagement, and overall visual appeal. Our team
              of experienced content and design professionals collaborates
              closely with clients to understand their messaging objectives and
              target audience. We carefully review the existing text,
              identifying opportunities for improvement, such as clarifying
              language, enhancing calls to action, or ensuring consistency
              throughout the site. When it comes to color, we consider factors
              like readability, contrast, and brand guidelines to select an
              appropriate text color that complements the overall design scheme.
              Additionally, we evaluate the size and font choices to ensure
              optimal legibility across different devices and screen sizes. By
              making these strategic adjustments, we create a cohesive and
              visually appealing textual presentation that effectively
              communicates the desired message and encourages users to engage
              with the content.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Add/Update/Remove photo</h3>
            <p>
              Adding, updating, or removing photos on a website is a crucial
              aspect of maintaining a visually appealing and engaging user
              experience. Our team of designers and developers collaborates
              closely with clients to understand their specific requirements and
              preferences regarding imagery. When adding new photos, we
              carefully select high-quality visuals that align with the
              website's purpose and target audience. We optimize the images for
              web use to ensure fast loading times without compromising on
              quality. In cases where existing photos need updating, we can
              enhance or retouch them to improve their visual impact. If certain
              photos are no longer relevant or necessary, we efficiently remove
              them to streamline the website's content and improve overall
              performance. Through thoughtful management of photos, we enhance
              the website's aesthetic appeal, align it with the brand identity,
              and create a visually captivating experience for visitors.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Add/Update/Remove blog post</h3>
            <p>
              Adding, updating, or removing blog posts on a website is an
              important part of keeping the content fresh, relevant, and
              engaging for your audience. Our team of content creators and
              editors works closely with clients to understand their content
              strategy and objectives. When adding new blog posts, we conduct
              thorough research on the topic, ensuring it aligns with your
              target audience's interests and addresses their needs. We craft
              well-written, informative, and engaging articles that provide
              value to readers. If existing blog posts require updates, we can
              refresh the content with new information, additional insights, or
              improved formatting to keep it current and valuable. In some
              cases, outdated or irrelevant blog posts may be removed to
              maintain a streamlined and up-to-date website. By effectively
              managing blog posts, we enhance your website's authority, improve
              SEO rankings, and provide valuable content that attracts and
              retains visitors.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Add/Update/Remove event post</h3>
            <p>
              Adding, updating, or removing event posts on a website is
              essential for keeping your audience informed about upcoming events
              and maintaining an accurate calendar of activities. Our team
              understands the importance of providing timely and relevant event
              information to your visitors. When adding new event posts, we work
              closely with you to gather all the necessary details, such as
              event title, date, time, location, description, and any
              registration or ticketing information. We ensure that the event
              post is visually appealing and includes eye-catching graphics or
              images related to the event. In the case of updates, we promptly
              reflect any changes to the event details, such as rescheduled
              dates, venue updates, or additional information. If an event is
              canceled or no longer relevant, we efficiently remove the event
              post to avoid confusion. By managing event posts effectively, we
              help you maintain an organized and up-to-date event calendar,
              enabling visitors to stay informed and engage with your upcoming
              events.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">
              Add/Update/Remove component from page
            </h3>
            <p>
              Adding, updating, or removing components from a web page is
              crucial for maintaining a dynamic and well-structured website. Our
              team of web developers and designers works closely with clients to
              understand their specific needs and goals for each page. When
              adding a component, we carefully consider the desired
              functionality and visual presentation. Whether it's a contact
              form, image gallery, interactive map, or any other element, we
              ensure seamless integration and responsiveness across different
              devices. In the case of updates, we can modify existing components
              to incorporate new features, improve usability, or enhance the
              overall design. If a component is no longer required or serves no
              purpose, we efficiently remove it to streamline the page and
              optimize performance. By effectively managing page components, we
              enhance user experience, improve navigation, and create a visually
              appealing and functional website.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Change format of page</h3>
            <p>
              Changing the format of a web page involves reimagining its layout,
              structure, and overall design to enhance user experience, visual
              appeal, and functionality. Our team of skilled designers and
              developers collaborates closely with clients to understand their
              objectives and desired changes. We analyze the existing page,
              considering factors such as content organization, readability, and
              user flow. Based on this analysis, we propose a new format that
              optimizes the placement of key elements, improves navigation, and
              creates a visually captivating experience. This may include
              adjusting the arrangement of sections, repositioning images or
              media, incorporating intuitive menus, or implementing a grid-based
              or responsive design approach. By implementing the new format, we
              transform the page into a well-structured, aesthetically pleasing,
              and user-friendly interface that effectively communicates the
              desired message and enhances overall engagement.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Add/Update/Remove functionality</h3>
            <p>
              Adding, updating, or removing functionality from a website
              involves customizing its features and capabilities to better align
              with your business goals and user needs. Our team of experienced
              developers works closely with clients to understand their specific
              requirements and desired changes. When adding new functionality,
              we carefully analyze the objectives and select the most suitable
              tools and technologies to implement the feature effectively.
              Whether it's adding a new user login system, incorporating a
              search function, integrating social media sharing options, or
              implementing a custom payment gateway, we ensure seamless
              integration and thorough testing. For updates, we can enhance
              existing functionality, optimize performance, or address any bugs
              or issues. If certain features are no longer needed or are causing
              confusion, we efficiently remove them to declutter the website and
              improve user experience. By effectively managing website
              functionality, we create a customized and efficient platform that
              elevates user engagement, streamlines processes, and supports your
              business growth.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Bug fixes/troubleshooting</h3>
            <p>
              Bug fixes and troubleshooting are critical aspects of website
              maintenance, ensuring smooth functionality and a seamless user
              experience. Our team of skilled developers is well-versed in
              identifying and resolving various technical issues that may arise.
              When it comes to bug fixes, we conduct thorough investigations to
              pinpoint the root cause of the problem. This involves analyzing
              error logs, examining code snippets, and replicating the issue to
              identify and resolve any coding or configuration errors. We
              prioritize prompt resolution to minimize any impact on your
              website's performance. In terms of troubleshooting, we proactively
              identify and address any issues that may arise, such as broken
              links, slow page loading times, or compatibility problems across
              different browsers and devices. Our team applies rigorous testing
              and quality assurance procedures to ensure that all components of
              your website function optimally. By providing efficient bug fixes
              and troubleshooting services, we ensure that your website operates
              smoothly, delivering a positive experience for your users.
            </p>
            <br />
            <h3 className="medium_text color1 roboto">Misc. Additions/Updates/Removals</h3>
            <p>
              Our team specializes in handling miscellaneous additions, updates,
              and removals to ensure the ongoing optimization and maintenance of
              your website. From updating content and improving navigation to
              managing plugins and optimizing performance, we cover a wide range
              of tasks. We ensure that your website remains up-to-date and
              aligned with your business goals by incorporating new content,
              streamlining menus, managing forms, optimizing images, enhancing
              performance, and implementing necessary compliance and security
              measures. Our dedicated approach to handling these miscellaneous
              tasks ensures that your website continues to operate smoothly,
              engages users effectively, and stays in line with the latest
              standards and practices.
            </p>
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color1">website</span> is a vital online
              presence for businesses and individuals, enabling information
              sharing, connection, and essential transactions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
