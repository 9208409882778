import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";

export default function Websites() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <p className="all_caps">Happy Code Dev</p>
          <h1 className="roboto xlarge_text all_caps">Websites</h1>
          <Spacer height={15} />
          <div>
            <p>
              In the present digital landscape, establishing a compelling online
              presence through a meticulously designed website is pivotal for
              individuals and businesses across diverse sectors. Opting for the
              services of{" "}
              <span
                className="pointer color1"
                onClick={() => {
                  navigate("/");
                }}
              >
                Innovative Internet Creations - Happy Code
              </span>{" "}
              ensures an up-to-the-minute online profile. A professionally
              crafted website serves as a versatile platform, enhancing
              visibility, ccolor1ibility, and accessibility. It facilitates
              seamless interaction with a global audience and fosters
              trust-building, ultimately contributing to increased conversions.
              In the current dynamic environment, partnering with Innovative
              Internet Creations guarantees a contemporary and impactful web
              solution, aligning with the latest trends and user expectations
              for an optimal online experience.
            </p>
          </div>
          <Spacer height={15} />
          <h3 className="bold large_text">Links</h3>

          <div className="websites_row1_links">
            <div className="">
              <Link1
                text={"Members"}
                classes={"underline courier medium_text color1 all_caps"}
                onClick={() => {
                  navigate("/website-members");
                }}
              />
              <Link1
                text={"Services"}
                classes={"underline courier medium_text color1 all_caps"}
                onClick={() => {
                  navigate("/website-services");
                }}
              />
              <Link1
                text={"Pages"}
                classes={"underline courier medium_text color1 all_caps"}
                onClick={() => {
                  navigate("/website-pages");
                }}
              />
              <Link1
                text={"FAQ"}
                classes={"underline courier medium_text color1 all_caps"}
                onClick={() => {
                  navigate("/website-faq")
                }}
              />
            </div>
            <div className="no_scrollY">
              <Image
                image={img1}
                height={"100%"}
                width={"100%"}
                classes={"cover"}
                radius={0}
              />
            </div>
          </div>
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color1">website</span> is a vital online
              presence for businesses and individuals, enabling information
              sharing, connection, and essential transactions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
