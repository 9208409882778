import React from "react";

export function Link1({ text, size, font, onClick, classes }) {
  return (
    <p
      onClick={() => {
        onClick !== undefined ? onClick() : console.log("No click events");
      }}
      className={`${classes} bg_clear ${font} pointer main_body_font no_margin no_padding`}
      style={{
        fontSize: size,
      }}
    >
      {text}
    </p>
  );
}
