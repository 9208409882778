import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";

export default function Contact() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <p className="all_caps">Innovative Internet Creations</p>
          <h1 className="roboto xlarge_text all_caps">Contact Us</h1>
          <Spacer height={15} />
          <div className="padding">
            <p>bagel@happycodewebsites.com</p>
            <p>619 875 6441</p>
            <p>Based in California, United States | Tokyo, Japan</p>
          </div>
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              Our mission is to leverage our creativity and innovative spirit to
              craft websites, apps, software, and games that businesses and
              communities can value, enjoy, and utilize to simplify their lives
              and operations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
