import React, { useEffect } from "react";
import { Link1 } from "../COMPONENTS/Link";
//
import "../IIC STYLES/Websites.css";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import Navigation from "./Navigation";
import { Spacer } from "../COMPONENTS/Spacer";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { BiArrowBack } from "react-icons/bi";

export default function AppMembers() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);
  return (
    <div className="fade_in courier" style={{ flex: 1 }}>
      <div className="websites_row1">
        {/* MAIN CONTENT */}
        <div className="padding_large">
          <div
            className="horizontal pointer"
            style={{ alignItems: "center" }}
            onClick={() => {
              navigate("/apps");
            }}
          >
            <BiArrowBack size={20} color="black" />
            <p>Back</p>
          </div>
          <Spacer height={15} />
          <p className="all_caps">Mobile Apps</p>
          <h1 className="roboto xlarge_text all_caps">Members</h1>
          <Spacer height={15} />
          <div>
            <p>
              <span className="color2">Innovative Internet Creations</span>{" "}
              provides services for app maintenance every month; unlike any
              other company. There may be a need to add new features to make the app be a better fit for users. Our innovative team knows to implement just about anything imaginable.
            </p>
            <br />
            <p>
              Our Innovative Internet Creations mobile app lets you create
              tickets for updates, view data coming from user interaction with
              the app, pay invoices, get real-time updates from developer,
              communicate with development staff, and more. This app is
              available for iPhone and Android mobile devices.
            </p>
          </div>
          <Spacer height={15} />
        </div>
        {/* NAV */}
        <div className="bg1 separate_vertical">
          <Navigation />
          <div className="bg_white padding">
            <p className="xsmall_text">
              A <span className="color2">mobile app</span> is a pivotal digital
              hub, enabling businesses and individuals to access information,
              connect seamlessly, and conduct essential transactions on the go.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
