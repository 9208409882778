import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../Constants";
import "../App.css";
import "../IIC STYLES/Home.css";
//
import img1 from "../IIC PHOTOS/abstract.jpg";
import { Image } from "../COMPONENTS/Image";
import { Link1 } from "../COMPONENTS/Link";
import Footer from "./Footer";

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = routes.find(
      (route) => `/${route.path}` === location.pathname
    ).title;
  }, []);

  return (
    <div className="fade_in" style={{ flex: 1 }}>
      {/* ROW 1 */}
      <div className="home_row1 no_scrollY courier">
        <div className="bg1 padding line_height kerning full_height separate_vertical">
          <h1 className="no_margin medium_text roboto">
            Innovative
            <br /> Internet
            <br /> Creations
          </h1>
          <p className="all_caps">
            We make websites, apps, software, and games.
          </p>
        </div>
        <div className="full_height no_scrollY">
          <Image
            image={img1}
            height={"100%"}
            width={"100%"}
            classes={"cover"}
            radius={0}
          />
        </div>
        <div className="full_height bg_black white  separate_vertical">
          <div>
            <p className="padding all_caps medium_text">
              Our mission is to leverage our creativity and innovative spirit to
              craft websites, apps, software, and games that businesses and
              communities can value, enjoy, and utilize to simplify their lives
              and operations.
            </p>
          </div>
          <div className="padding">
            <p className="red">Websites, Apps, Software, Games</p>
            <h1 className="xxlarge_text all_caps line_height roboto">
              Code Development Services
            </h1>
          </div>
        </div>
      </div>
      {/* ROW 2 */}
      <div className="home_row2 no_scrollY courier">
        <div className="padding separate_vertical">
          <div></div>
          <div>
            <p className="red xsmall_text">CEO, Executive Developer</p>
            <p className="all_caps roboto bold">Jesus Jimenez Santos</p>
          </div>
        </div>
        <div className="padding bg2 white">
          <Link1
            text={"Websites"}
            font={"white underline all_caps medium_text courier"}
            onClick={() => {
              navigate("/websites");
            }}
          />
          <Link1
            text={"Apps"}
            font={"white underline all_caps medium_text courier"}
            onClick={() => {
              navigate("/apps")
            }}
          />
          <Link1
            text={"Pop! Market"}
            font={"white underline all_caps medium_text courier"}
            onClick={() => {
              alert("Pop! Market");
            }}
          />
          <Link1
            text={"Contact"}
            font={"white underline all_caps medium_text courier"}
            onClick={() => {
              navigate("/contact")
            }}
          />
        </div>
        <div className="no_scrollY">
          <Image
            radius={0}
            image={img1}
            height={"100%"}
            width={"100%"}
            classes={"cover"}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
